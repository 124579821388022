import Box from "@mui/material/Box/Box";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MantineReactTable } from "mantine-react-table";
import { MRT_Localization_ZH_HANS } from "mantine-react-table/locales/zh-Hans";
import { useRef } from "react";
import { Title } from "react-admin";
import { updateMenu } from "../../api";
const columns = [
  {
    accessorKey: "label_cn",
    header: "中文导航名称",
  },
  {
    accessorKey: "label_en",
    header: "英文导航名称",
  },
];
export default function MenuList({ items }: any) {
  const queryClient = useQueryClient();
  const tableInstanceRef = useRef<any>(null);

  const mutation = useMutation({
    mutationFn: updateMenu,
  });

  const handleSaveRow = async ({ exitEditingMode, row, values }: any) => {
    //if using flat data and simple accessorKeys/ids, you can just do a simple assignment here.
    items[row.index] = { ...items[row.index], ...values };
    // console.log("row items:", items, values);
    //send/receive api updates here
    await mute(items);
    exitEditingMode(); //required to exit editing mode
  };

  const mute = async (items: any) => {
    try {
      mutation.mutate(items, {
        onSuccess(data) {
          queryClient.setQueryData(["menu"], data);
        },
      });
    } catch (error) {
      console.log("update menu error:", error);
    }
  };
  return (
    <>
      <Title title={"导航"} />
      <Box>
        <MantineReactTable
          editingMode="row"
          onEditingRowSave={handleSaveRow}
          enableRowNumbers
          enablePagination={false}
          enableStickyFooter
          localization={MRT_Localization_ZH_HANS}
          tableInstanceRef={tableInstanceRef}
          getRowId={(originalRow) => `${(originalRow as any)?.key}`}
          columns={columns as any}
          data={items}
          enableHiding={false}
          enableColumnActions={false}
          enableFullScreenToggle={false}
          enableFilters={false}
          enableDensityToggle={false}
          enableEditing
          enableRowOrdering
          enableColumnFilterModes={false}
          enableSorting={false}
          enableRowActions
          positionActionsColumn="last"
          mantineTableContainerProps={{ sx: { maxHeight: "75vh" } }}
          mantineRowDragHandleProps={({ table }) => ({
            onDragEnd: async () => {
              const { draggingRow, hoveredRow } = table.getState();
              if (hoveredRow && draggingRow) {
                items.splice(
                  (hoveredRow as any).index,
                  0,
                  items.splice(draggingRow.index, 1)[0]
                );
                await mute(items);
              }
            },
          })}
        />
      </Box>
    </>
  );
}
