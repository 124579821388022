import TitleIcon from "@mui/icons-material/Title";
import Box from "@mui/material/Box/Box";
import InputAdornment from "@mui/material/InputAdornment/InputAdornment";
import TextInput from "../components/textInput";
import TinyEditor from "../components/tinyEditor";
import UploadImage from "../components/uploadImage";
import WizardForm from "../components/wizardForm";
import WizardFormStep from "../components/wizardFormStep";
export const ExhibitionTemplate = () => {
  return (
    <WizardForm>
      <WizardFormStep label="封面和标题">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
            alignItems: "flex-start",
            mt: 5,
            // bgcolor: "red",
            // background: "red !important",
          }}
        >
          <Box sx={{ mr: 8 }}>
            <UploadImage source="coverUrl" />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <TextInput
              source="title_cn"
              label="中文标题"
              validate={"请填写中文标题"}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <TitleIcon />
                  </InputAdornment>
                ),
              }}
            />
            <TextInput
              sx={{
                mt: 4,
              }}
              source="title_en"
              label="英文标题"
              validate={"请填写英文标题"}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <TitleIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
      </WizardFormStep>
      <WizardFormStep label="封面中文描述">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 5,
          }}
        >
          <TinyEditor source="desc_cn" />
        </Box>
      </WizardFormStep>
      <WizardFormStep label="封面英文描述">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mt: 5,
          }}
        >
          <TinyEditor source="desc_en" />
        </Box>
      </WizardFormStep>
      <WizardFormStep label="中文详情">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mt: 5,
          }}
        >
          <TinyEditor source="content_cn" />
        </Box>
      </WizardFormStep>
      <WizardFormStep label="英文详情">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mt: 5,
          }}
        >
          <TinyEditor source="content_en" />
        </Box>
      </WizardFormStep>
    </WizardForm>
  );
};
