import Box from "@mui/material/Box/Box";
import Button from "@mui/material/Button";
import Step from "@mui/material/Step/Step";
import StepButton from "@mui/material/StepButton";
import Stepper from "@mui/material/Stepper/Stepper";
import { Children, isValidElement, useRef, useState } from "react";
import { useSaveContext } from "react-admin";
import { FormProvider, useForm } from "react-hook-form";
type WizardFormPropsType = {
  children: any;
  [key: string]: any;
  onSubmit?: (params: { [key: string]: string }) => void;
  autoSubmit?: boolean;
};
export default function WizardForm(props: WizardFormPropsType) {
  const methods = useForm();
  const [activeStep, setActiveStep] = useState(0);
  const updateDataRef = useRef({});
  const { save } = useSaveContext() ?? {};
  const { children, onSubmit: customSubmit, autoSubmit = false } = props;
  const { formState, trigger, handleSubmit } = methods;
  const { dirtyFields, isValid } = formState;
  const stepLength = Children.toArray(children).length;
  const handleBack = () => {
    setActiveStep((prevActiveStep) => Math.max(prevActiveStep - 1, 0));
  };
  const handleNext = async () => {
    await trigger();
    if (!isValid) {
      return;
    }
    if (activeStep < stepLength - 1) {
      setActiveStep((prevActiveStep) =>
        Math.min(prevActiveStep + 1, stepLength - 1)
      );
    } else {
      handleSubmit(onSubmit)();
    }
  };

  const handleUpdate = async () => {
    await trigger();
    if (!isValid) {
      return;
    }
    handleSubmit(onSubmit)();
  };
  const onSubmit = (data: { [key: string]: any }) => {
    updateDataRef.current = {
      ...updateDataRef.current,
      ...Object.keys(data)
        .filter((key) => key in dirtyFields)
        .reduce((obj, key) => {
          obj[key] = data[key];
          return obj;
        }, {} as any),
    };
    console.log("updateDataRef.current:", data, updateDataRef.current);
    if (customSubmit) {
      customSubmit(updateDataRef.current);
    } else {
      save?.(updateDataRef.current);
    }
  };
  const steps = Children.toArray(children).map((child) => {
    if (isValidElement(child) && child?.props?.label) {
      return child.props.label;
    }
  });
  return (
    <Box
      sx={{
        p: 4,
      }}
    >
      <Box sx={{ width: "100%" }}>
        <Stepper activeStep={activeStep} nonLinear>
          {steps.map((label, index) => {
            return (
              <Step key={label}>
                <StepButton onClick={() => setActiveStep(index)}>
                  {label}
                </StepButton>
              </Step>
            );
          })}
        </Stepper>
      </Box>
      <FormProvider {...methods}>
        {children.map((child: any, index: number) => (
          <Box
            key={steps[activeStep].label}
            sx={{
              display: activeStep === index ? "block" : "none",
            }}
          >
            {child}
          </Box>
        ))}
      </FormProvider>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "enter",
          mt: 6,
        }}
      >
        <Button
          variant="contained"
          onClick={handleBack}
          disabled={activeStep === 0}
        >
          {"上一步"}
        </Button>
        <Box>
          {activeStep < stepLength - 1 && (
            <Button
              variant="contained"
              onClick={handleNext}
              sx={{
                mr: 2,
              }}
            >
              {"下一步"}
            </Button>
          )}
          {!autoSubmit && (
            <Button variant="contained" onClick={handleUpdate}>
              {"提交"}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
}
