import { CircularProgress, Modal } from "@mui/material";
import Box from "@mui/material/Box";
import { Editor } from "@tinymce/tinymce-react";
import { nanoid } from "nanoid";
import * as qiniu from "qiniu-js";
import { useEffect, useRef, useState } from "react";
import { useRecordContext } from "react-admin";
import { useFormContext } from "react-hook-form";
import { IMG_STYLE } from "../config/imgStyle";
import { getToken } from "../utility/dataProvider";
import { baseLinkUrl } from "./uploadImage";
const config = {
  useCdnDomain: true,
  chunkSize: 2,
  concurrentRequestLimit: 5,
};

export default function TinyEditor({ source }: { source: string }) {
  const [open, setOpen] = useState(false);
  console.log(open);
  const editorRef = useRef<any>(null);
  const { register, setValue } = useFormContext();
  const record = useRecordContext() ?? {};
  const [model, setModel] = useState(record?.[source]);
  useEffect(() => {
    setValue(source, model, {
      shouldDirty: true,
    });
  }, [model, setValue, source]);
  const handleModelChange = (model: string) => {
    setModel(model);
  };
  return (
    <>
      <Box
        sx={{
          width: 1210,
          height: "calc(100vh - 300px)",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Editor
          onPaste={() => {
            console.log("exec");
          }}
          tinymceScriptSrc={process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"}
          onInit={(_, editor) => {
            editorRef.current = editor;
            register(source);
          }}
          value={model}
          onEditorChange={handleModelChange}
          init={{
            plugins:
              "preview autolink  directionality visualblocks visualchars image link media charmap nonbreaking insertdatetime advlist lists quickbars emoticons",
            menubar: "file edit view insert format tools",
            toolbar:
              "undo redo  | forecolor backcolor removeformat |  blockquote | preview | fontfamily fontsize lineheight blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | charmap emoticons | image media link | bold italic underline strikethrough",
            toolbar_sticky: true,
            image_advtab: true,

            quickbars_insert_toolbar: false,
            // "image link media insertdatetime hr",
            quickbars_selection_toolbar:
              "bold italic | forecolor backcolor removeformat | fontfamily fontsize lineheight blocks | link blockquote ",
            toolbar_mode: "sliding",
            font_size_input_default_unit: "px",
            draggable_modal: false,
            elementpath: false,
            width: "100%",
            height: "100%",
            font_size_formats:
              "14px 15px 16px 17px 18px 19px 20px 21px 22px 23px 24px 25px 26px 27px 28px 29px 30px 36px 48px",
            // paste_remove_styles_if_webkit: false,
            // TODO: scroller
            font_family_formats:
              "Times New Roman=Times New Roman,Times, serif; 宋体=宋体,STSong,SimSun;黑体=黑体,STHeiti, SimHei;仿宋=仿宋,STFangsong,FangSong;楷体=KaiTi, STKaiti;Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Ariallack=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courierew=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol;ahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco;Trebuchet MS=trebuchet ms,geneva; Verdana=verdana, geneva;ebdings=webdings; Wingdings=wingdings,zapf dingbats",
            menu: {
              file: {
                title: "File",
                items: "preview | print",
              },
              view: {
                title: "View",
                items: "visualaid visualchars visualblocks |  preview",
              },
            },
            language: "zh-Hans",
            language_url: `${process.env.PUBLIC_URL}/tinymce/langs/zh-Hans.js`,
            file_picker_callback(callback, value, meta) {
              console.log("value:", value);
              const input = document.createElement("input");
              input.setAttribute("type", "file");
              input.addEventListener("change", async (e) => {
                const target = e.target as HTMLInputElement;
                const file = target?.files?.[0];
                const fileName = file?.name.split(".")[0];

                try {
                  setOpen(true);
                  const token = await getToken();
                  const observable = qiniu.upload(
                    file as File,
                    nanoid(),
                    token,
                    undefined,
                    config
                  );
                  observable.subscribe(
                    null,
                    (error: any) => {
                      console.error("upload image failed:", error);
                    },
                    (res: any) => {
                      const key = res?.key;
                      let url = `${baseLinkUrl}${key}`;
                      if (meta.filetype == "image") {
                        url = `${url}?${IMG_STYLE.EDITOR_IMG_STYLE}`;
                        callback(url, {
                          width: "100%",
                          height: "auto",
                          alt: fileName,
                        });
                      } else {
                        callback(url);
                      }
                    }
                  );
                } catch (error) {
                  console.error("upload image failed:", error);
                }
                setOpen(false);
              });
              input.click();
            },
            media_dimensions: false,
            contextmenu: false,
            //   "image link media copy paste | fontfamily fontsize lineheight",
            content_style:
              "body { font-family:Times New Roman, Timee, serif; font-size:18px; line-height: 1.5 } p{margin:0} .mce-preview-object.mce-object-video { width: 100%} video {width: 100%; height: auto}",
          }}
        ></Editor>
      </Box>
      <Modal
        open={open}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box>
          上传中，请稍等 <CircularProgress />
        </Box>
      </Modal>
    </>
  );
}
