import { ReactNode } from "react";

type WizardFormStepPropsType = {
  children: ReactNode;
  label: string;
};
export default function WizardFormStep(props: WizardFormStepPropsType) {
  const { children } = props;
  return <>{children}</>;
}
