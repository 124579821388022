import MenuBookIcon from "@mui/icons-material/MenuBook";
import { useQuery } from "@tanstack/react-query";
import { Admin, Resource, useAuthenticated } from "react-admin";
import { getMenu } from "../api";
import MenuList from "../pages/menu/list";
import { authProvider } from "../utility/authProvider";
import dataProvider from "../utility/dataProvider";
import { Config } from "./config";
const App = () => {
  const { data } = useQuery({
    queryKey: ["menu"],
    queryFn: getMenu,
    // refetchOnWindowFocus: false,
  });
  if (!data) {
    return null;
  }

  const items: { key: string }[] = data?.data?.routes;
  const Menu = () => {
    useAuthenticated();
    return <MenuList items={items} />;
  };
  return (
    <Admin dataProvider={dataProvider as any} authProvider={authProvider}>
      <Resource
        icon={MenuBookIcon}
        options={{ label: "导航" }}
        name="menu"
        list={Menu}
      />
      {items?.map(({ key }) => {
        const { label, list, create, edit, icon } = Config[key];
        return (
          <Resource
            key={key}
            icon={icon}
            options={{ label }}
            name={key}
            list={list}
            create={create}
            edit={edit}
          />
        );
      })}
    </Admin>
  );
};

export default App;
