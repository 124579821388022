import { ActionIcon, Box, Tooltip } from "@mantine/core";
import Button from "@mui/material/Button/Button";
import { IconEdit } from "@tabler/icons-react";
import { MRT_ColumnDef, MRT_Row, MantineReactTable } from "mantine-react-table";
import { MRT_Localization_ZH_HANS } from "mantine-react-table/locales/zh-Hans";
import { useRef } from "react";
import {
  Title,
  useInfiniteGetList,
  useRefresh,
  useResourceContext,
} from "react-admin";

import { useNavigate } from "react-router";
import DeleteActionButton from "../components/deleteActionButton";
import { TitleMap } from "../utility/const";
import dataProvider from "../utility/dataProvider";

const List = <T extends Record<string, never>>(props: {
  meta: string[];
  columns: MRT_ColumnDef<T>[];
}) => {
  const { columns, meta } = props;
  const resource = useResourceContext();
  const navigate = useNavigate();
  const refresh = useRefresh();
  const { data, hasNextPage, fetchNextPage, isLoading, isFetching } =
    useInfiniteGetList(
      resource,
      {
        pagination: { page: 1, perPage: 5 },
        meta,
      },
      {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
      }
    );

  const tableData = data?.pages.flatMap((page) => page.data) ?? [];
  const tableInstanceRef = useRef<any>(null);
  const loadMore = () => {
    if (isLoading || isFetching || !hasNextPage) {
      return;
    }
    fetchNextPage();
  };
  return (
    <>
      <Title title={TitleMap[resource]} />
      <Box>
        <MantineReactTable
          enableStickyHeader
          enableRowNumbers
          enablePagination={false}
          enableStickyFooter
          localization={MRT_Localization_ZH_HANS}
          tableInstanceRef={tableInstanceRef}
          renderTopToolbarCustomActions={() => {
            return (
              <Box
                sx={{
                  position: "relative",
                  width: "100%",
                }}
              >
                <Button
                  onClick={() => {
                    navigate("create");
                  }}
                  variant="contained"
                  sx={{
                    position: "absolute",
                    right: 10,
                    top: 0,
                  }}
                >
                  新建
                </Button>
              </Box>
            );
          }}
          getRowId={(originalRow) => `${originalRow.id}`}
          columns={columns as any}
          data={tableData}
          enableHiding={false}
          enableColumnActions={false}
          enableFullScreenToggle={false}
          enableFilters={false}
          enableDensityToggle={false}
          enableEditing
          enableRowOrdering
          enableColumnFilterModes={false}
          enableSorting={false}
          enableRowActions
          positionActionsColumn="last"
          mantineTableContainerProps={{ sx: { maxHeight: "75vh" } }}
          renderRowActions={({ row }) => (
            <Box sx={{ display: "flex", gap: "16px" }}>
              <Tooltip withArrow position="bottom" label="Edit">
                <ActionIcon
                  onClick={() => {
                    navigate(`${row.id}`);
                  }}
                >
                  <IconEdit />
                </ActionIcon>
              </Tooltip>
              <DeleteActionButton row={row} />
            </Box>
          )}
          renderBottomToolbar={() => {
            return hasNextPage ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  onClick={loadMore}
                  sx={{
                    my: 2,
                  }}
                >
                  {isFetching ? "加载中..." : "加载更多"}
                </Button>
              </Box>
            ) : null;
          }}
          mantineRowDragHandleProps={({ table }) => ({
            onDragEnd: async () => {
              const { draggingRow, hoveredRow } = table.getState();

              if (hoveredRow && draggingRow) {
                const preIndx = (hoveredRow as MRT_Row<T>)?.index;
                const curId = draggingRow?.id;
                const preId = preIndx > 0 ? tableData[preIndx - 1].id : null;

                const nextId = hoveredRow?.id;
                const idsMap: {
                  preId?: string;
                  nextId?: string;
                  curId: string;
                } = { curId };
                if (preId) {
                  idsMap.preId = preId;
                }

                if (nextId) {
                  idsMap.nextId = nextId;
                }
                try {
                  await dataProvider.reorder(resource, { data: idsMap });
                  refresh();
                } catch (error) {
                  console.log("order error:", error);
                }
              }
            },
          })}
        />
      </Box>
    </>
  );
};

export default List;
