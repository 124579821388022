import {
  Create,
  RecordContextProvider,
  Title,
  useAuthenticated,
  useGetOne,
  useResourceContext,
} from "react-admin";
import { BioagraphyTemplate } from "../../template/singlePageTemplate";
import { TitleMap, router } from "../../utility/const";
const bioagraphyColumns = [
  { label: "编辑中文简历", source: "content_cn" },
  { label: "编辑英文简历", source: "content_en" },
];
const contactColumns = [
  { label: "编辑中文联系", source: "content_cn" },
  { label: "编辑英文联系", source: "content_en" },
];
export default function Biograhpy() {
  useAuthenticated();
  const resource = useResourceContext();
  const columns =
    resource === router.biography ? bioagraphyColumns : contactColumns;
  const { data, isFetching, isLoading } = useGetOne(
    resource,
    {
      id: resource,
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      // refetchOnMount: false,
    }
  );

  if (isFetching || isLoading) {
    return null;
  }

  return (
    <>
      <Title title={`编辑${TitleMap[resource]}`}></Title>
      {data ? (
        <RecordContextProvider value={data}>
          <BioagraphyTemplate columns={columns} />;
        </RecordContextProvider>
      ) : (
        <Create>
          <BioagraphyTemplate columns={columns} />;
        </Create>
      )}
    </>
  );
}
