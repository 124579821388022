import Box from "@mui/material/Box/Box";
import { useNotify, useRecordContext, useResourceContext } from "react-admin";
import TinyEditor from "../components/tinyEditor";
import WizardForm from "../components/wizardForm";
import WizardFormStep from "../components/wizardFormStep";
import { apiUrl, headers, httpClient } from "../utility/dataProvider";
type ColumnType = {
  label: string;
  source: string;
  key?: string;
};
export type PropsType = {
  columns: ColumnType[];
};
export const BioagraphyTemplate = (props: PropsType) => {
  const { columns } = props;
  const resource = useResourceContext();
  const record = useRecordContext();
  const notify = useNotify();
  const update = async (req: any) => {
    const method = record ? "PUT" : "POST";
    const notification = record ? "更新成功" : "发布成功";
    try {
      await httpClient(`${apiUrl}/${resource}`, {
        method,
        headers,
        body: JSON.stringify(req),
      });

      notify(notification, { type: "success" });
    } catch (error) {
      console.error("error:", error);
      notify("网络错误请稍后重试", { type: "success" });
    }
  };
  return (
    <WizardForm onSubmit={update}>
      {columns.map(({ label, source, key }) => (
        <WizardFormStep label={label} key={key ?? `${label}-${source}`}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              mt: 5,
            }}
          >
            <TinyEditor source={source} />
          </Box>
        </WizardFormStep>
      ))}
    </WizardForm>
  );
};
