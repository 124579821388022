import { Edit, useRecordContext, useResourceDefinition } from "react-admin";
import { ExhibitionTemplate } from "../../template/exhibition";
const PostTitle = () => {
  const record = useRecordContext();
  const {
    options: { label },
  } = useResourceDefinition();
  return <span>正在编辑{record ? `${label}: ${record.title_cn}` : ""}</span>;
};
export const ExhibitionEdit = () => {
  return (
    <Edit sx={{ width: "1210px" }} title={<PostTitle />}>
      <ExhibitionTemplate />
    </Edit>
  );
};
