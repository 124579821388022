import TextField, { TextFieldProps } from "@mui/material/TextField/TextField";
import { useRecordContext } from "react-admin";
import { useFormContext } from "react-hook-form";
type Variant = "filled" | "outlined" | "standard";
type TextInputPropsType = {
  source: string;
  [key: string]: any;
} & {
  variant?: Variant;
} & Omit<TextFieldProps, "variant">;
export default function TextInput(props: TextInputPropsType) {
  const {
    source,
    label,
    variant = "outlined",
    // validate = "The field is required",
    ...rest
  } = props;
  const { register, getFieldState } = useFormContext();
  const { error } = getFieldState(source);
  const record = useRecordContext();
  return (
    <TextField
      {...rest}
      defaultValue={record?.[source] ?? ""}
      id={source}
      label={label ?? source}
      variant={variant}
      error={!!error?.message}
      helperText={error?.message}
      // {...register(source, { required: validate })}
      {...register(source)}
    />
  );
}
