import { useAuthenticated } from "react-admin";
import { IMG_STYLE } from "../../config/imgStyle";
import List from "../../template/list";
const colums = [
  {
    accessorKey: "title_cn",
    header: "标题",
  },
  {
    accessorKey: "coverUrl",
    header: "封面",
    Cell(props: any) {
      const { renderedCellValue } = props;
      return (
        <img
          style={{ maxHeight: "100px", width: "auto" }}
          src={`${renderedCellValue}?${IMG_STYLE.LIST_COVER_STYLE}`}
        />
      );
    },
  },
];

const meta = ["order", "title_cn", "desc_cn", "coverUrl"];
export default function WorkList() {
  useAuthenticated();
  return <List columns={colums} meta={meta} />;
}
