import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import Box from "@mui/material/Box/Box";
import Typography from "@mui/material/Typography/Typography";
import { useUpdateEffect } from "ahooks";
import { Upload } from "antd";
import { nanoid } from "nanoid";
import * as qiniu from "qiniu-js";
import type { UploadRequestOption as RcCustomRequestOptions } from "rc-upload/lib/interface";
import { useEffect, useState } from "react";
import { useRecordContext } from "react-admin";
import { useFormContext } from "react-hook-form";
import { IMG_STYLE } from "../config/imgStyle";
import { getToken } from "../utility/dataProvider";
import "./index.css";

export const baseLinkUrl = `https://cnd.source.caoyu.art/`;

type uploadImageMetaInfo = {
  source: string;
  validate?: string;
  [key: string]: any;
};
const UploadImage = (props: uploadImageMetaInfo) => {
  const { source } = props;
  const { register, getFieldState, setValue } = useFormContext();
  const { error } = getFieldState(source);
  const record = useRecordContext() ?? {};
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>(record?.[source]);
  useEffect(() => {
    // register(source, { required: "必须上传封面" });
    register(source);
  }, [register, source]);

  useUpdateEffect(() => {
    setValue(source, imageUrl, {
      shouldDirty: true,
    });
  }, [imageUrl, setValue, source]);

  const customRequest = async (req: RcCustomRequestOptions) => {
    const { file } = req;
    const config = {
      useCdnDomain: true,
      chunkSize: 2,
      concurrentRequestLimit: 5,
    };
    setLoading(true);
    try {
      const token = await getToken();

      const observable = qiniu.upload(
        file as File,
        nanoid(),
        token,
        undefined,
        config
      );
      observable.subscribe(
        null,
        () => {
          setLoading(false);
          console.error("upload image failed:", error);
        },
        async (res: any) => {
          const key = res?.key;
          const url = `${baseLinkUrl}${key}`;
          setImageUrl(url);
          setLoading(false);
        }
      );
    } catch (error) {
      setLoading(false);
      console.error("upload image failed:", error);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Typography
        variant="subtitle2"
        gutterBottom
        sx={{
          textAlign: "center",
          fontSize: "0.75rem",
          fontWeight: 400,
          color: error?.message ? "#d32f2f" : "rgba(0, 0, 0, 0.6)",
          mb: 1.5,
        }}
      >
        编辑作品封面
      </Typography>
      <Upload
        name="cover"
        listType="picture-card"
        className="cover-uploader"
        showUploadList={false}
        customRequest={customRequest}
      >
        {loading ? (
          <LoadingOutlined rev={undefined} />
        ) : imageUrl ? (
          <img
            src={`${imageUrl}?${IMG_STYLE.DETAIL_COVER_STYLE}`}
            alt="cover"
            style={{ width: 200, height: 200 }}
          />
        ) : (
          <PlusOutlined rev={undefined} />
        )}
      </Upload>
      {error?.message && (
        <Typography
          variant="body2"
          gutterBottom
          sx={{
            textAlign: "center",
            fontSize: "0.75rem",
            color: "#d32f2f",
            mt: 1,
          }}
        >
          {error.message}
        </Typography>
      )}
    </Box>
  );
};

export default UploadImage;
